@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Poppins:wght@400;600;700&display=swap');

body {
	font-family: 'Poppins', sans-serif;
	font-size: 11px !important;
}

.topbar {
	background-color: #222222;
	min-height: 63px;
	/* border-top-left-radius: .7rem;
	border-top-right-radius: .7rem; */

}

.show {
	display: block;
}

.noshow {
	display: none;
}

.error-status {
	background-color: red;
    border-radius: 10px;
    color: #ffff;
}

.success-status {
	background-color: green;
    border-radius: 10px;
    color: #ffff;
}

.no-status {
	background-color: white;
    color: #212529;
}

.topbar .logo {
	/* width: 230px; 
	height: 60px; */
	/* background: url("../images/logo.png"); */
	background-repeat: no-repeat;
	background-size: contain;
  /* text-indent: -9999px; */
	color: #fff !important;
	font-size: 16px;
	/* margin-bottom: .5rem; */
	font-weight: 500;
	line-height: 1.2;
	margin-right: auto;
	font-family: 'Alfa Slab One', cursive;
	margin-left: 2%;
}

.navbar-nav {
	font-family: 'Alfa Slab One', cursive;
}

.navbar-dark .navbar-nav .nav-link {
	color: #FFF;
}
.navbar-dark .navbar-nav .nav-link:hover {
	color: #F04E34;
}

.navbar-dark .navbar-nav .active  .nav-link {
	color: #F04E34;
	border-bottom: 1px solid #F04E34;
}


.link-room {
	padding-left: 25px !important;
	background: url("../images/icon-room.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;
}

.active .link-room {
	background: url("../images/icon-salas-active.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;

}

.link-reservation {
	padding-left: 25px !important;
	background: url("../images/icon-reservas.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;
}

.active .link-reservation {
	background: url("../images/icon-reservas-active.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;

}

.link-studentreservation {
	padding-left: 25px !important;
	background: url("../images/icon-reservas.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;
}

.active .link-studentreservation {
	background: url("../images/icon-reservas-active.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;

}

.link-logout {
	padding-left: 25px !important;
	background: url("../images/icon-salir.png");
	background-size: 16px 16px;
	background-position: 0 12px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.content-container {
	background-color: #F4F5F9;
}

h1 {
	font-family: 'Alfa Slab One', cursive;
	color: #333;
	font-size: 60px;
}

h2 {
	font-family: 'Alfa Slab One', cursive;
	color: #F04E34;
}

h4, h2 {
	font-size: 14px !important;
}

.mt-4, .my-4{
	margin-top: auto !important;
}

.btn-primary{
	background-color: black !important;
	border-color: black !important;
	margin-left: 10px;
    margin-top: 10px;
}

.btn-primary:hover{
	background-color: gray !important;
	border-color: gray !important;
}

.btn-secondary{
	background-color: gray !important;
	border-color: gray !important;
	margin-top: 10px;
    margin-left: 10px;
}

.btn-secondary:hover{
	background-color: black !important;
	border-color: black !important;
}

.btn-orange {
	background-color: #F04E34;
	border-color: #D5361D;
}

.btn-orange:hover {
	background-color: #2F27AA;
	border-color: #2F27AA;
}

.btn-purple {
	background-color: #2F27AA;
	border-color: #2F27AA;
}

.btn {
	font-size: 11px !important;
}

thead tr{
	height: 50px;
	background-color: #222222;
	color: #fff;
}

tr{
	height: 45px;
	border-bottom: 3px solid #F4F5F9;
	background-color: #FFF;
}

td, th{
	vertical-align: middle !important;
}

td {
	font-weight: 600;
}

.table-hover tbody tr:hover {
	background-color: #FFF4F2;
}

.table-description {
	display: block;
	font-weight: 100;
}

.container-pagination span {
	font-weight: 700;
}

.container-pagination .page-link {
	border: 2px solid #000000;
	color: #000000;
	font-weight: 700;
	margin: 0 3px !important;
}

.container-pagination .page-link:hover {
	background-color: #000000 !important;
	color: #FFF !important;
}

.active .page-link {
	border: 2px solid #000000 !important;
	background-color: #000000 !important;
	color: #fff;
}

.modal-title {
	font-family: 'Alfa Slab One', cursive;
	color: #F04E34;
	font-size: 40px;
}
.modal label {
	font-weight: 700;
}

.modal-text{
	margin-right: 1.5rem !important;
	font-weight: bold !important;
}

.modal-footer :not(second-child){
	margin: 0px;
}
.error {
	border: 1px solid red;
  }
.a span {
    pointer-events: none;
}  


@media (min-width: 1200px) { 
	.container {
		max-width: 1300px;
	}
}
@media (min-width: 1400px) { 
	.container {
		max-width: 1500px;
	}
}